import axios from 'axios'
import { configure } from 'axios-hooks'

axios.defaults.baseURL = process.env.REACT_APP_URL_BACKEND
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.headers.post.Accept = 'application/json'
axios.defaults.headers.put.Accept = 'application/json'
//axios.defaults.withCredentials = true

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_BACKEND,
  timeout: 300000,
  validateStatus: (status) => {
    const defaultStatus = status >= 200 && status < 300
    const extra = status === 409
    return defaultStatus || extra
  }
})

configure({ axios: axiosInstance })

export default axiosInstance
