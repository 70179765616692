import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PrincipalLayout from './Pages/Principal/PrincipalLayout'
import BookingLayout from './Pages/Booking'
import AmbienteLayout from './Pages/Ambientes/AmbienteLayout'
import ContactoLayout from './Pages/Contacto'


class Root extends React.Component {
  renderApp () {
    return (
      <Router basename={process.env.REACT_APP_CONTEXT}>
        <Routes>
          <Route path='/' exact element={<PrincipalLayout/>} />
          <Route path='/masajistas' exact element={<PrincipalLayout/>} />
          <Route path='/rooms' exact element={<AmbienteLayout/>}/>
          <Route path='/contacto' exact element={<ContactoLayout/>}/>
          <Route path='/booking' exact element={<BookingLayout/>} />
        </Routes>
      </Router>
    )
  }

  render () {
    return this.renderApp()
  }
}

export default Root