import React from 'react'
import Header from '../../components/HeaderTop'
import Footer from '../../components/Footer'
import { Box } from '@mui/material'

import Logo from '../../assets/logo.png'
import { NavLink } from 'react-router-dom'

// Estilos en línea
const styles = {
  card: {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '15px',
      margin: '10px',
      width: '400px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  name: {
      margin: '0 0 10px 0',
      color: '#333'
  },
  info: {
      margin: '5px 0',
      color: '#555'
  }
}

const ContactoLayout = () => {

  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box display="flex" justifyContent="center" mt={2}>
        <div style={styles.card}>
          <h2><img src={Logo} alt='Logo' style={{width: '50%'}}/></h2>
          <h2 style={styles.name}>
            {'Masajes Bliss 55'}</h2>
          <p style={styles.info}>{'Comunícate con nosotros llamando al: +56947386871'}</p>
          <p style={styles.info}>{'Agenda con el siguiente link: '}
            <NavLink to={'https://wa.me/56947386871?text=Hola%20quiero%20preguntar%20por%20los%20servicios'}>WhatsApp</NavLink>
          </p>
          <h4>Nuestros servicios:</h4>
          <p>1. Masajes de relajación con el mejor oral incluido (cc) 25.000</p>
          <p>2. Servicio exprés media hora de contacto oral incluido (cc)  35.000</p>
          <p>3. Full masaje + contacto 45.000</p>
          <p>4. Duales promo  35.000~ </p>
          <p><b>A 2 cuadras metro plaza de Armas, San Antonio con Monjitas</b></p>
          <h4>Horarios:</h4>
          <p>10.45~20h lunes a viernes y sábados 11~17h</p>
        </div>
      </Box>
      <Box component="footer" mt="auto">
        <Footer />
      </Box>
    </Box>
  )
}

export default ContactoLayout