import React from 'react'
import Header from '../../components/HeaderTop'
import ImageCarousel from '../../components/ImageCarousel'
import Footer from '../../components/Footer'
import { Box } from '@mui/material'

import Red1 from '../../assets/rooms/red-1.jpeg'
import Red2 from '../../assets/rooms/red-2.jpeg'
import Red3 from '../../assets/rooms/red-3.jpeg'
import Blue from '../../assets/rooms/blue-1.jpeg'
import Purple from '../../assets/rooms/purple-1.jpeg'

const AmbienteLayout = () => {
  
  const images = [
    {
      nickname: 'Rojo',
      portada: Red1
    }, {
      nickname: 'Purple',
      portada: Purple
    }, {
      nickname: 'Rojo',
      portada: Red2
    }, {
      nickname: 'Blue',
      portada: Blue
    }, {
      nickname: 'Rojo',
      portada: Red3
    }
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box display="flex" justifyContent="center" mt={2}>
      </Box>
      <Box>
        <ImageCarousel images={images}/>
      </Box>
      <Box component="footer" mt="auto">
        <Footer />
      </Box>
    </Box>
  )
}

export default AmbienteLayout