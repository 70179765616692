import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#4107', color: '#fff', padding: '20px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {/* Columna de logo y nombre */}
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center" flexDirection="column">
              {/* Asumiendo que tienes un componente Logo */}
              {/* <Logo /> */}
              
              <Typography variant="h6">
                <NavLink to={'/'} style={{color: 'white', textDecoration: 'none'}}>Bliss 55</NavLink>
              </Typography>
            </Box>
          </Grid>

          {/* Columnas de enlaces */}
          <Grid item xs={6} sm={2}>
            {/* Cada grupo de enlaces */}
            <Typography variant="subtitle1">
              <NavLink to={'/contacto'} style={{color: 'white', textDecoration: 'none'}}>CONTACTO</NavLink>
            </Typography>
            
          </Grid>

          {/* Columna de texto legal */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">
              Portal dedicado al servicio de masajes para varones, mayores de 18 años
              Todas las masajistas han sido entrevistadas personalmente.
              Queda prohibida la copia o reproducción, total o parcial.
            </Typography>
          </Grid>
        </Grid>

        {/* Texto de derechos de autor */}
        <Box mt={5} textAlign="center">
          <Typography variant="body2">
            © 2020-2023 Bliss 55, Santiago de Chile. Todos los derechos reservados
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
