import React from 'react'
import Header from '../../components/HeaderTop'
import ImageCarousel from '../../components/ImageCarousel'
import Footer from '../../components/Footer'
import { Box } from '@mui/material'

import Ignacia1 from '../../assets/models/ignacia-1.jpeg'
import Ignacia2 from '../../assets/models/ignacia-2.jpeg'
import Ignacia4 from '../../assets/models/ignacia-4.jpeg'
import Ignacia5 from '../../assets/models/ignacia-5.jpeg'
import Nicolle1 from '../../assets/models/nicolle-1.jpeg'
import Daniela1 from '../../assets/models/daniela-1.jpeg'
import Daniela2 from '../../assets/models/daniela-2.jpeg'
import Sara1 from '../../assets/models/sara-1.jpeg'
import Sara2 from '../../assets/models/sara-2.jpeg'
import Iza1 from '../../assets/models/iza-1.jpeg'


const PrincipalLayout = () => {
  
  const images = [
    {
      nickname: 'Ignacia',
      portada: Ignacia2
    }, {
      nickname: 'Ignacia',
      portada: Ignacia4
    }, {
      nickname: 'Ignacia',
      portada: Ignacia5
    }, {
      nickname: 'Nicolle',
      portada: Nicolle1
    }, {
      nickname: 'Sara',
      portada: Sara1
    }, {
      nickname: 'Sara',
      portada: Sara2
    }, {
      nickname: 'Daniela',
      portada: Daniela1
    }, {
      nickname: 'Daniela',
      portada: Daniela2
    }, {
      nickname: 'Iza',
      portada: Iza1
    }
  ]

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box display="flex" justifyContent="center" mt={2}>
      </Box>
      <Box>
        <ImageCarousel images={images}/>
      </Box>
      <Box component="footer" mt="auto">
        <Footer />
      </Box>
    </Box>
  )
}

export default PrincipalLayout