import React from 'react'
import { Box } from '@mui/material'
import Header from '../../components/HeaderTop'
import Footer from '../../components/Footer'
import RegistrationForm from '../../components/RegistrationForm' // El formulario de registro que creaste
import useAxios from 'axios-hooks'
import axiosInstance from '../../services/axiosInstance'

const BookingLayout = () => {
  const [{ data: masajistasData, loading: loadingMasajistas }, refreshMasajistas] = useAxios({
    axiosInstance: axiosInstance,
    url: '/v1/masajistas',
    method: 'GET'
  }, { useCache: false });

  // ... imágenes y otros estados/componentes

  if (loadingMasajistas) {
    return (
      <div align='left'>
        <h1>Cargando...</h1>
      </div>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box display="flex" justifyContent="center" mt={2}>
        
        <RegistrationForm masajistas={masajistasData ? masajistasData.data : []} />
      </Box>
      <Box component="footer" mt="auto">
        <Footer />
      </Box>
    </Box>
  )
}

export default BookingLayout;
