import React, { useRef } from 'react'
import Slider from 'react-slick'
import { IconButton, Paper, Box, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImageCarousel = ({ images }) => {
  const sliderRef = useRef()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', mt: 2 }}>
      <IconButton
        onClick={previous}
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translate(0, -50%)',
          zIndex: 1
        }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Paper elevation={3} sx={{ padding: '20px', '.slick-slide': { padding: '0 10px' } }}>
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, idx) => (
            <Box key={idx} position="relative" sx={{ 
              width: 300,           // Ancho fijo del Box
              height: 350,          // Altura fija del Box
              display: 'flex',      
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              margin: 'auto'        // Esto centra el Box si es más pequeño que su contenedor
          }}>
            <img src={image.portada} alt={`Slide ${idx}`} style={{ display: 'block', width: '100%' }}/>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{
                position: 'absolute',
                bottom: -310,
                width: '100%',
                textAlign: 'center',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '5px 0',
              }}
            >
              {image.nickname}
            </Typography>
          </Box>
          ))}
        </Slider>
      </Paper>
      <IconButton
        onClick={next}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translate(0, -50%)',
          zIndex: 1
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  )
}

export default ImageCarousel
