import React from 'react'
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu' // Si tienes un menú responsivo

import Logo from '../../assets/logo.png'


const Header = () => {
  return (
    <AppBar position="static" color="default" sx={{ backgroundColor: '#4107', color: '#fff' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          <NavLink to={'/'} style={{color: 'white', textDecoration: 'none'}}>
            <img src={Logo} alt='Logo' style={{width: '5%'}}/>
          </NavLink>
        </Typography>
        <Button color="inherit">
          <NavLink to={'/'} style={{color: 'white', textDecoration: 'none'}}>INICIO</NavLink>
        </Button>
        <Button color="inherit">
        <NavLink to={'/'} style={{color: 'white', textDecoration: 'none'}}>MASAJISTAS</NavLink>
        </Button>
        <Button color="inherit">
          <NavLink to={'/rooms'} style={{color: 'white', textDecoration: 'none'}}>AMBIENTES</NavLink>
        </Button>
        {/*<Button color="inherit">BOOKING</Button>*/}
        <Button color="inherit">
          <NavLink to={'/contacto'} style={{color: 'white', textDecoration: 'none'}}>CONTACTO</NavLink>
        </Button>
        {/* ... más botones */}
      </Toolbar>
    </AppBar>
  )
}

export default Header
