import React from 'react'
import Root from './main'

class App extends React.Component {
  render(){
    return (
        <Root {...this.props}/>
    );
  }
}

export default App
