import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
// import * as Yup from 'yup'
import { Box, Button, FormControl, InputLabel, Select, MenuItem, TextField, Typography, Container } from '@mui/material'

const initialValues = {
  type: 'SINGLE',
  masajistaUid: [],
  registrationTime: '',
  otherDetails: '',
}


/* const validationSchema = Yup.object().shape({
  type: Yup.string().required('Requerido'),
  masajistaUid: Yup.array().of(Yup.string()).when('type', {
    is: 'SINGLE',
    then: Yup.array().of(Yup.string()).required('Requerido').max(1, 'Debe seleccionar solo una masajista'),
    is: 'DUAL',
    then: Yup.array().of(Yup.string()).required('Requerido').min(2, 'Debe seleccionar dos masajistas'),
    otherwise: Yup.array().of(Yup.string()).ensure(),
  }),
  otherDetails: Yup.string().when('type', {
    is: 'OTRO',
    then: Yup.string().required('Detalle del servicio es requerido'),
  }),
  registrationTime: Yup.date().required('Requerido'),
}) */

const RegistrationForm = ({masajistas}) => {
  return (
    <Container component="main" maxWidth="sm">
      <Box sx={{ width: '100%', mt: 2, mb: 4 }}>
        <Typography variant='h4' align='center' gutterBottom>
          Formulario de Registro
        </Typography>
        <Formik
          initialValues={initialValues}
          //validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            console.log(values);
            // Aquí manejarías el envío del formulario a tu backend
            actions.setSubmitting(false);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box sx={{ marginBottom: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="type-label">Tipo de Atención</InputLabel>
                  <Field
                    as={Select}
                    labelId="type-label"
                    id="type"
                    name="type"
                    label="Tipo de Atención"
                    onChange={(event) => setFieldValue('type', event.target.value)}
                  >
                    <MenuItem value="SINGLE">SINGLE</MenuItem>
                    <MenuItem value="DUAL">DUAL</MenuItem>
                    <MenuItem value="OTRO">OTRO</MenuItem>
                  </Field>
                  <ErrorMessage name="type" component="div" />
                </FormControl>
              </Box>

              {/* Condicionales basados en el tipo de atención seleccionada */}
              {values.type !== 'OTRO' && (
                <Box sx={{ marginBottom: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="masajistaUid-label">Masajista(s)</InputLabel>
                    <Select
                      labelId="masajistaUid-label"
                      id="masajistaUid"
                      //multiple
                      value={values.masajistaUid}
                      onChange={(event) => setFieldValue('masajistaUid', event.target.value)}
                      //renderValue={(selected) => selected.map(uid => masajistas.find(m => m.uid === uid)?.nickname).join(', ')}
                    >
                      {masajistas.map((masajista) => (
                        <MenuItem key={masajista.uid} value={masajista.uid}>
                          {masajista.nickname}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="masajistaUid" component="div" />
                  </FormControl>
                </Box>
              )}

              {values.type === 'OTRO' && (
                <Box sx={{ marginBottom: 2 }}>
                  <Field
                    as={TextField}
                    label="Detalle del Servicio"
                    name="otherDetails"
                    fullWidth
                  />
                  <ErrorMessage name="otherDetails" component="div" />
                </Box>
              )}

              <Box sx={{ marginBottom: 2 }}>
                <Field
                  as={TextField}
                  type="datetime-local"
                  label="Hora de Registro"
                  name="registrationTime"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
                <ErrorMessage name="registrationTime" component="div" />
              </Box>

              <Button type="submit" variant="contained" color="primary">
                Registrar
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  )
}

export default RegistrationForm
